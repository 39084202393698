<template>
  <div>
    <InvoiceForm
      :invoice="selectedInvoice"
      :isInvoiceModalOpen="isInvoiceModalOpen"
      @dismiss="closeModal"
      :mode="mode"
    />

    <ConfirmDelete
      :dialog="confirmDeleteInvoice"
      :item="selectedInvoice"
      @delete="deleteInvoice($event)"
      @dismiss="dismissFn($event)"
    />

    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between">
          <div>
            <h2>Documents</h2>
            <span class="text--disabled">
              View, create, edit and delete documents
            </span>
          </div>

          <!-- <v-btn color="blue-grey" class="ma-2 white--text">
            <v-icon class="ma-2" right dark> mdi-download </v-icon>
            New Document
          </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-card-title class="font-weight-medium">Overview</v-card-title>
          <v-divider></v-divider>
          <v-row class="mt-2">
            <v-col class="ma-2">
              <v-sheet rounded>
                <v-card flat color="blue lighten-5">
                  <v-card-title class="card-title">
                    <v-col>
                      <v-row
                        ><div class="d-flex align-center">
                          <span> Pending</span>
                        </div></v-row
                      ><v-row class="mt-4">
                        <h2 class="font-weight-medium">
                          {{ overview[0]?.total }}
                        </h2>
                        <v-spacer />
                        <h2 class="font-weight-medium">
                          ${{ overview[0]?.total_amount }}
                        </h2></v-row
                      >
                    </v-col></v-card-title
                  >
                </v-card>
              </v-sheet></v-col
            >
            <v-col class="ma-2">
              <v-sheet rounded>
                <v-card flat color="green lighten-5">
                  <v-card-title class="card-title">
                    <v-col>
                      <v-row
                        ><div class="d-flex align-center">
                          <span> Paid</span>
                        </div></v-row
                      ><v-row class="mt-4">
                        <h2 class="font-weight-medium">
                          {{ overview[1]?.total }}
                        </h2>
                        <v-spacer />
                        <h2 class="font-weight-medium">
                          ${{ overview[1]?.total_amount }}
                        </h2></v-row
                      >
                    </v-col></v-card-title
                  >
                </v-card>
              </v-sheet></v-col
            >
            <v-col class="ma-2">
              <v-sheet rounded>
                <v-card flat color="red lighten-5">
                  <v-card-title class="card-title">
                    <v-col>
                      <v-row
                        ><div class="d-flex align-center">
                          <span> Overdue</span>
                        </div></v-row
                      ><v-row class="mt-4">
                        <h2 class="font-weight-medium">
                          {{ overview[2]?.total }}
                        </h2>
                        <v-spacer />
                        <h2 class="font-weight-medium">
                          ${{ overview[2]?.total_amount }}
                        </h2></v-row
                      >
                    </v-col></v-card-title
                  >
                </v-card>
              </v-sheet></v-col
            >
          </v-row>

          <v-divider></v-divider>

          <v-row class="mt-2 mb-2">
            <v-col cols="12">
              <v-card-text>
                <h2 class="font-weight-medium">All Documents</h2>
              </v-card-text>

              <v-divider></v-divider>

              <v-row class="ma-2">
                <v-col
                  ><div class="d-flex">
                    <v-btn color="blue-grey" class="white--text">
                      <v-icon class="ma-2" right dark> mdi-plus </v-icon>
                      New Document
                    </v-btn>
                  </div></v-col
                >
                <v-spacer></v-spacer>
                <v-col cols="3"
                  ><v-text-field
                    outlined
                    depressed
                    dense
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-divider></v-divider>

              <v-row
                ><v-col cols="12">
                  <v-data-table
                    hide-default-footer
                    :headers="headers"
                    :items="documents"
                    :loading="loading"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.status="{ item }">
                      <div v-if="item.status == 'paid'" class="d-flex">
                        <v-icon color="success" x-small left>mdi-circle</v-icon>
                        <span class="font-weight-medium">Paid</span>
                      </div>

                      <div v-else-if="item.status == 'Pending'" class="d-flex">
                        <v-icon color="orange" x-small left>mdi-circle</v-icon>
                        <span class="font-weight-medium">Pending</span>
                      </div>

                      <div v-else-if="item.status == 'unpaid'" class="d-flex">
                        <v-icon color="error" x-small left>mdi-circle</v-icon>
                        <span class="font-weight-medium">Unpaid</span>
                      </div>
                    </template>

                    <!-- action -->
                    <template #[`item.action`]="{ item }">
                      <v-menu left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="viewInvoice(item)">
                            <v-list-item-title
                              ><v-icon fab small class="me-2"
                                >mdi-download</v-icon
                              >View Invoice</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item @click="editInvoice(item)">
                            <v-list-item-title
                              ><v-icon fab small class="me-2"
                                >mdi-pencil</v-icon
                              >
                              Edit Invoice
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="setDeleteInvoice(item)">
                            <v-list-item-title
                              ><v-icon small color="error" class="me-2"
                                >mdi-delete</v-icon
                              >
                              Delete Invoice</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                  <v-divider></v-divider>
                  <v-card-actions
                    class="d-flex justify-space-between align-center pa-4"
                  >
                    <v-btn
                      outlined
                      class="font-transform-inherit"
                      color="#C6C5C5"
                      small
                    >
                      <span class="dashboard-blue"
                        >Showing page {{ pagination.page }}</span
                      >
                    </v-btn>
                    <v-pagination
                      v-model="pagination.page"
                      :length="pagination.totalItems"
                      circle
                      prev-icon="mdi-arrow-left"
                      next-icon="mdi-arrow-right"
                      color="#3371E2"
                      class="custom"
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-card-actions> </v-col
              ></v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InvoiceForm from "../../../components/Billing/InvoiceForm.vue"
import ConfirmDelete from "../../../components/ConfirmDelete.vue"

export default {
  components: {
    InvoiceForm,
    ConfirmDelete,
  },
  data() {
    return {
      mode: "new",
      isInvoiceModalOpen: false,
      confirmDeleteInvoice: false,
      selectedInvoice: null,
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
      },
      search: "",
      headers: [
        {
          text: "Number",
          value: "number",
        },
        {
          text: "Campaign",
          value: "campaign",
        },
        {
          text: "Customer",
          value: "customer",
        },
        {
          text: "Issue Date",
          value: "due_date",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "",
          value: "action",
          align: "center",
        },
      ],
      documents: [
        {
          number: "M/24/0001",
          campaign: "Nivea Campaign",
          customer: "Nivea Kenya",
          issue_date: "Jan 12, 2023",
          due_date: "Feb 22, 2023",
          amount: "$4,500",
          status: "paid",
        },
        {
          number: "M/24/0002",
          campaign: "Adidas Campaign",
          customer: "Adidas Kenya",
          issue_date: "Feb 5, 2023",
          due_date: "Mar 15, 2023",
          amount: "$6,200",
          status: "unpaid",
        },
        {
          number: "M/24/0003",
          campaign: "Coca-Cola Campaign",
          customer: "Coca-Cola Kenya",
          issue_date: "Mar 18, 2023",
          due_date: "Apr 28, 2023",
          amount: "$8,700",
          status: "unpaid",
        },
        {
          number: "M/24/0004",
          campaign: "Samsung Campaign",
          customer: "Samsung Kenya",
          issue_date: "Apr 9, 2023",
          due_date: "May 19, 2023",
          amount: "$3,900",
          status: "paid",
        },
        {
          number: "M/24/0005",
          campaign: "Nike Campaign",
          customer: "Nike Kenya",
          issue_date: "May 22, 2023",
          due_date: "Jun 30, 2023",
          amount: "$5,600",
          status: "unpaid",
        },
        {
          number: "M/24/0006",
          campaign: "Pepsi Campaign",
          customer: "Pepsi Kenya",
          issue_date: "Jun 7, 2023",
          due_date: "Jul 17, 2023",
          amount: "$7,300",
          status: "paid",
        },
        {
          number: "M/24/0007",
          campaign: "Apple Campaign",
          customer: "Apple Kenya",
          issue_date: "Jul 28, 2023",
          due_date: "Sep 6, 2023",
          amount: "$9,100",
          status: "unpaid",
        },
        {
          number: "M/24/0008",
          campaign: "Toyota Campaign",
          customer: "Toyota Kenya",
          issue_date: "Aug 14, 2023",
          due_date: "Sep 24, 2023",
          amount: "$2,400",
          status: "paid",
        },
        {
          number: "M/24/0009",
          campaign: "McDonald's Campaign",
          customer: "McDonald's Kenya",
          issue_date: "Sep 19, 2023",
          due_date: "Oct 29, 2023",
          amount: "$4,800",
          status: "unpaid",
        },
        {
          number: "M/24/0010",
          campaign: "Sony Campaign",
          customer: "Sony Kenya",
          issue_date: "Oct 8, 2023",
          due_date: "Nov 17, 2023",
          amount: "$6,000",
          status: "paid",
        },
      ],
    }
  },
  mounted() {
    this.getInvoices()
    this.getInvoiceOverview()
  },
  computed: {
    invoices() {
      return this.$store.state.billing.invoices
    },
    overview() {
      let template = [
        { total: 0, total_amount: "0.00", status: "pending" },
        { total: 0, total_amount: "0.00", status: "paid" },
        { total: 0, total_amount: "0.00", status: "overdue" },
      ]

      let data = this.$store.state.billing.invoices_overview

      // Iterate over the template array
      template.forEach((item, index) => {
        // Find the matching item in the response array based on status
        let matchingResponse = data.find(
          (responseItem) => responseItem.status === item.status
        )
        // If a matching response item is found, update the template
        if (matchingResponse) {
          template[index] = { ...item, ...matchingResponse }
        }
      })

      return template
    },
  },
  watch: {
    invoices() {
      this.pagination.totalItems = this.invoices?.total
      this.pagination.page = this.invoices?.current_page
    },
  },
  methods: {
    viewInvoice(item) {
      this.$router.push({
        name: "invoice-preview",
        params: {
          id: item.id,
        },
      })
    },
    closeModal() {
      this.isInvoiceModalOpen = false
      this.selectedInvoice = null
    },
    openCreateNewInvoiceModal() {
      this.mode = "new"
      this.isInvoiceModalOpen = true
    },
    async getInvoices() {
      this.loading = true
      try {
        await this.$store.dispatch("billing/fetchInvoices", this.pagination)
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async getInvoiceOverview() {
      this.loading = true
      try {
        await this.$store.dispatch("billing/fetchInvoicesOverview")
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async createInvoice() {
      this.$router.push({
        name: "invoice-preview",
        params: {
          id: "new",
        },
      })
    },
    async editInvoice(invoice) {
      this.selectedInvoice = invoice

      this.mode = "edit"

      this.isInvoiceModalOpen = true
    },
    async downloadInvoice(invoice) {
      this.$router.push({
        name: "invoice-preview",
        params: {
          id: invoice.invoice_id,
        },
      })
    },
    dismissFn() {
      this.confirmDeleteInvoice = false
      this.selectedInvoice = null
    },
    setDeleteInvoice(po) {
      this.selectedInvoice = po
      this.confirmDeleteInvoice = true
    },
    async deleteInvoice(id) {
      this.loading = true

      try {
        this.confirmDeleteInvoice = false

        await this.$store.dispatch("billing/deleteInvoice", id)

        this.getInvoices()

        this.getInvoiceOverview()

        this.$notify({
          title: "Invoice deleted successfully",
          group: "success",
        })
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    handlePageChange(value) {
      this.pagination.page = value

      this.getInvoices()
    },
  },
}
</script>
