<template>
  <div>
    <v-navigation-drawer
      :value="isInvoiceModalOpen"
      @input="handleDrawerModelValueUpdate"
      fixed
      temporary
      right
      width="40%"
    >
      <v-card flat>
        <v-card-title class="py-2 pr-3 justify-space-between">
          <h5>{{ mode == "new" ? "New Invoice" : "Update Invoice" }}</h5>
          <v-btn icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle>
          <v-alert
            text
            dense
            color="teal"
            icon="mdi-alert-circle"
            border="left"
          >
            Fill in the form below to create a new invoice
          </v-alert>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-row class="flex-column">
              <v-col>
                <v-select
                  :rules="clientRules"
                  :menu-props="{
                    maxHeight: '400',
                    bottom: true,
                    offsetY: true,
                  }"
                  hide-details="auto"
                  outlined
                  dense
                  :items="campaigns"
                  item-text="name"
                  return-object
                  label="Select campaign"
                  v-model="campaign"
                  placeholder="Select campaign"
                ></v-select>
              </v-col>

              <v-col>
                <v-select
                  :rules="clientRules"
                  :menu-props="{
                    maxHeight: '400',
                    bottom: true,
                    offsetY: true,
                  }"
                  hide-details="auto"
                  outlined
                  dense
                  :items="__customers"
                  item-text="name"
                  return-object
                  label="Select Customer"
                  v-model="customer"
                  placeholder="Select customer"
                ></v-select>
              </v-col>

              <v-col>
                <v-select
                  :rules="serviceRules"
                  :menu-props="{
                    maxHeight: '400',
                    bottom: true,
                    offsetY: true,
                  }"
                  hide-details="auto"
                  outlined
                  dense
                  :items="services"
                  item-text="name"
                  return-object
                  label="Select Service"
                  v-model="service"
                  placeholder="Select service"
                ></v-select>
              </v-col>

              <v-col>
                <v-text-field
                  hide-details="auto"
                  outlined
                  dense
                  v-model="description"
                  label="Description"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  hide-details="auto"
                  outlined
                  dense
                  type="number"
                  v-model="vat"
                  label="VAT (%)"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      v-model="invoice_date"
                      label="Invoice Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice_date"
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      v-model="date"
                      label="Due Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-text-field
                  hide-details="auto"
                  type="number"
                  outlined
                  dense
                  v-model="amount"
                  label="Amount"
                  :rules="amountRules"
                ></v-text-field>

              </v-col>

              <v-col>
                <v-text-field
                  hide-details="auto"
                  type="number"
                  disabled
                  outlined
                  dense
                  v-model="total"
                  label="Total"
                ></v-text-field>

              </v-col>

              <v-col v-if="mode == 'edit'">
                <v-text-field
                  hide-details="auto"
                  type="number"
                  outlined
                  dense
                  v-model="paid"
                  label="Amount Paid"
                ></v-text-field>

              </v-col>

              <v-col v-if="mode == 'edit'">
                <v-select
                  :menu-props="{
                    maxHeight: '400',
                    bottom: true,
                    offsetY: true,
                  }"
                  v-model="status"
                  hide-details="auto"
                  outlined
                  dense
                  :items="['pending', 'paid', 'overdue']"
                  label="Invoice Status"
                  placeholder="Select status"
                ></v-select>
              </v-col>

              <v-col>
                <v-textarea
                  v-model="notes"
                  filled
                  name="input-7-4"
                  label="Invoice notes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>

      <template #append>
        <v-card>
          <v-card-text>
            <v-btn
              :disabled="loading"
              :loading="loading"
              type="submit"
              @click="submitForm"
              block
              depressed
              color="primary"
              class="mt-2"
              >Submit</v-btn
            >
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  props: {
    isInvoiceModalOpen: {
      type: Boolean,
      required: true,
    },
    invoice: {
      type: Object,
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  data() {
    return {
      valid: true,
      amount: null,
      paid: null,
      clientsLoading: false,
      loading: false,
      status: null,
      customer: null,
      taxes: [],
      service: null,
      campaign: null,
      amountRules: [(value) => !!value || "Amount is required"],
      clientRules: [(value) => !!value || "Customer is required"],
      serviceRules: [(value) => !!value || "Service is required"],
      description: "",
      notes: "",
      vat: 0,
      date: null,
      invoice_date: null,
      menu2: false,
      menu3: false,
      lastNameRules: [
        (value) => {
          if (/[^0-9]/.test(value)) return true

          return "Last name can not contain digits."
        },
      ],
      services: [
        { name: "Programmatic Display", description: "" },
      ]
    }
  },
  watch: {
    isInvoiceModalOpen() {
      if (this.isInvoiceModalOpen) {
        this.getPurchaseOrders()
        this.getCampaigns()
      }
    },
    campaign(){
      if(this.mode == "new"){
        this.amount = this.campaign?.budget
      }
    },
    invoice() {
      if (this.invoice && this.mode == "edit") {
        this.campaign = this.invoice?.campaign;
        this.customer = this.invoice?.customer;

        this.service = this.services.find(
          (service) => service.name == this.invoice?.service
        );

        this.description = this.invoice?.description;
        this.vat = this.invoice?.vat;
        this.invoice_date = this.invoice?.invoice_date;
        this.date = this.invoice?.due_date;

        //convert this.invoice.amount to number and set it to this.amount
        this.amount = Number(this.invoice?.amount)

        this.notes = this.invoice?.notes;
        this.status = this.invoice?.status;
        this.paid = this.invoice?.paid;
      }
    },
  },
  methods: {
    dismiss() {
      this.$emit("dismiss")
    },
    handleDrawerModelValueUpdate(value) {
      if (value == false) {
        this.$emit("dismiss")
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
    },
    submitForm() {
      const isvalid = this.$refs.form.validate()

      if (isvalid) {
        let data = {
          service: this.service?.name,
          campaign_id: this.campaign?.id,
          customer_id: this.customer?.id,
          amount: this.amount,
          currency: this.currency,
          description: this.description,
          vat: this.vat,
          auto_invoice_no: true,
          notes: this.notes,
          due_date: this.date,
          invoice_date: this.invoice_date,
        }

        if(this.mode == "edit"){
          data = {
            ...data,
            status: this.status,
            paid: this.paid
          }
        }
        
        if (this.mode == "edit") {
          this.updateCampaignInvoice(data)
        } else if (this.mode == "new") {
          this.createInvoice(data)
        }
      }
    },

    async createInvoice(body) {
      this.loading = true

      try {
        await this.$store.dispatch("billing/createInvoice", {
          body: body,
        })

        this.$notify({
          title: "Invoice created successfully",
          group: "success",
        })

        this.dismiss()

        this.fetchInvoices()
        this.getInvoiceOverview()
      } catch (error) {
        this.$notify({
          title: error?.message,
          group: "errors",
        })
      } finally {
        this.loading = false
      }
    },

    async updateCampaignInvoice(body) {

      this.loading = true

      try {
        await this.$store.dispatch("billing/updateInvoice", {
          id: this.invoice?.id,
          body: body,
        })

        this.$notify({
          title: "Invoice updated successfully",
          group: "success",
        })

        this.dismiss()

        this.fetchInvoices()
        this.getInvoiceOverview()

      } catch (error) {
        this.$notify({
          title: error?.message,
          group: "errors",
        })
      } finally {
        this.loading = false
      }
    },

    //fetcg invoices
    async fetchInvoices() {
      let body = {
        page: 1,
        params: {
          per_page: 10,
        },
      }

      try {
        this.loading = true
        await this.$store.dispatch("billing/fetchInvoices", body)
        this.loading = false
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },

    async getInvoiceOverview() {
      this.loading = true
      try {
        await this.$store.dispatch("billing/fetchInvoicesOverview")
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },

    async getCampaigns() {
      this.loading = true
      try {
        await this.$store.dispatch("dsp/getAllDspCampaigns")
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async getPurchaseOrders() {
      this.loading = true
      try {
        await this.$store.dispatch("billing/fetchPOs")
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },

  },
  computed: {
    __isDrawerOpen() {
      return this.isInvoiceModalOpen
    },
    __customers() {
      return this.$store.state.billing.customers
    },
    formatTaxTypes() {
      return this.tax_types.map((tax) => {
        return {
          ...tax,
          name: `${tax.name} (${tax.rate}%)`,
        }
      })
    },
    ...mapState({
      campaigns: (state) => state.dsp.dsp_campaigns,
      tax_types: (state) => state.billing.tax_types,
    }),
    total(){
      let total =  this.amount + (this.amount * this.vat / 100);

      //return total to 2 decimal places
      return total.toFixed(2)
    }
  },
}
</script>
